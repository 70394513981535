import React from "react";
import { graphql } from "gatsby";

// JS Logic
import { getLocale } from "~utils";

// Components
import Layout from "~layout";
import ErrorComponent from "~layout/error";

const ErrorPage = ({ data }) => {
  const {
    frontmatter: { locale: localeKey, metadata, button },
  } = data.markdownRemark;

  const locale = getLocale(localeKey);

  return (
    <Layout helmetOnly={true} locale={locale} pageMetadata={metadata}>
      <ErrorComponent
        data={{
          title: metadata.title,
          description: metadata.description,
          button: button,
        }}
        locale={locale}
      />
    </Layout>
  );
};

export default ErrorPage;

export const pageQuery = graphql`
  query ErrorPage($locale: String) {
    markdownRemark(
      frontmatter: { templateKey: { eq: "404-page" }, locale: { eq: $locale } }
    ) {
      frontmatter {
        locale
        metadata {
          title
          description
        }
        button {
          text
          path
        }
      }
    }
  }
`;
