import React from "react";
import { Link } from "gatsby";

import { localizedUrl, useWindowSize } from "~utils";

const ErrorComponent = ({data: { title, description, button }, locale}) => {
  const { height: pageHeight } = useWindowSize();

  return (
    <section className="stf-404" style={{height: pageHeight}}>
      <div  className="stf-404__content">
        <h1>{title}</h1>
        <p>{description}</p>
        <Link to={localizedUrl(locale, button.path)} className="stf-button stf-button--accent">
          {button.text}
        </Link>
      </div>
    </section>
  );
};
export default ErrorComponent;
